import Service, { globalLinker } from "../Service";

export default class ReCaptchaService extends Service {
  constructor() {
    super("grecaptcha");
    this.forceLoad = true;
  }

  get variables() {
    return ["grecaptcha"].reduce(globalLinker, {});
  }

  afterScript(deps, resolve) {
    this.script.src = "https://www.google.com/recaptcha/api.js";

    this.script.onload = () => {
      resolve();
    };
  }
}
