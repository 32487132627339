import React, { useEffect, useState } from "react";
import { useFormHook } from "@utils/hooks/formHook";
import { validateSingleItem } from "@utils/validators/form";
import styled from "@emotion/styled";
import { defaultAction } from "@actions/defaultAction";
import {
  HIDE_SIGN_IN_POPUP_MODAL,
  HIDE_SIGNIN_PASS_ERROR,
  SET_RECAPTCHA,
  RESET_RECAPTCHA,
  DISPLAY_RECAPTCHA,
  DISPLAY_SIGNIN_PASS_ERROR
} from "@constants/accountTypes";
import { ClearedStyleRouterLink, ClearedStyleInput } from "@modules/Components";
import SubmitButton from "@components/SubmitButton";
import loadable from "@loadable/component";
const MessageContainer = loadable(() =>
  import(/* webpackChunkName: "MessageContainer" */ "../MessageContainer")
);
import { useDispatch, useSelector } from "react-redux";
import { initializeRecaptcha } from "@actions/accountActions";
import ReCaptchaService from "@modules/ServiceManager/Services/ReCaptchaService";

const InputSection = styled.div`
  margin-bottom: 32px;
`;

const InputDiv = styled.div`
  width: 100%;
  margin: 0 auto;
  border-radius: 5px;
  box-shadow: 0 20px 70px 0 rgba(34, 35, 36, 0.12);
  background-color: #ffffff;
  margin-top: 18px;
  display: grid;
  align-items: center;
  padding: 0;
  height: 72px;
  font-size: 16px;
  position: relative;
  grid-template-columns: 1fr;
`;

const Image = styled.img`
  opacity: ${props => props.imageOpacity};
  align-self: center;
  width: 16px;
  position: absolute;
  left: 25px;
  pointer-events: none;
`;

const InputContainer = styled.div`
  border-radius: 5px;
  height: 100%;
  width: 100%;
  align-items: center;
  display: flex;

  @media (max-width: 485px) {
    font-size: 14px;
  }
`;

const InputField = styled(ClearedStyleInput)`
  border: ${props => (props.error ? "solid 1px rgba(238, 80, 80, 0.48)" : "")};
  color: rgba(31, 32, 33, 0.65);
  opacity: 1;
  padding-left: 54px;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  padding-top: 0px;
  padding-bottom: 0px;

  &:focus {
    border: ${props =>
      props.error ? "solid 1px rgba(238, 80, 80, 0.48)" : "solid 2px #1f2021"};
    outline: none;
  }
  &:hover {
    border: ${props =>
      props.error ? "solid 1px rgba(238, 80, 80, 0.48)" : ""};
    outline: none;
  }
  &::placeholder {
    color: rgba(31, 32, 33, 0.65);
  }
  &::-webkit-input-placeholder {
    color: rgba(31, 32, 33, 0.65);
  }
  &:-ms-input-placeholder {
    color: rgba(31, 32, 33, 0.65);
  }
`;

const ErrorMessageInner = styled.div`
  color: #ee5050;
  font-size: 10px;
  letter-spacing: 1px;
  position: absolute;
  font-weight: 900;
  top: 3px;
  left: 25px;
`;

const ForgotPassword = styled.div`
  position: absolute;
  right: 25px;
  color: #12b2ea;
`;

const PasswordLink = styled(ClearedStyleRouterLink)`
  font-size: 14px;
  font-weight: bold;
  color: #12b2ea;
  cursor: pointer;
`;

const RecaptchaDiv = styled.div`
  margin-top: 5px;

  @media (max-width: 375px) {
    transform: scale(0.77);
    webkit-transform: scale(0.77);
    transform-origin: 0 0;
    webkit-transform-origin: 0 0;
  }
`;

const SignInForm = ({
  login,
  setClassName,
  submissionHistoryObject,
  recaptchaTarget
}) => {
  const dispatch = useDispatch();
  const showRecaptcha = useSelector(state => state.customerInfo.showRecaptcha);
  const recaptchaCode = useSelector(state => state.customerInfo.recaptchaCode);
  const invalidSignInPassword = useSelector(
    state => state.customerInfo.invalidSignInPassword
  );
  const passErrorMsg = useSelector(state => state.customerInfo.passErrorMsg);
  const showSignInPopUpModal = useSelector(
    state => state.userActions.showSignInPopUpModal
  );

  const [recatpchaDisable, setRecaptchaDisable] = useState(false);
  const [imageOpacity, setImageOpacity] = useState({
    email: 0.3,
    password: 0.3
  });

  useEffect(() => {
    dispatch(defaultAction(HIDE_SIGNIN_PASS_ERROR));
    window.scrollTo(0, 0);
    if (!globalThis?.grecaptcha) {
      getRecaptchaService();
    } else {
      setUpRecaptch();
    }
  }, []);

  const getRecaptchaService = async () => {
    await dispatch(initializeRecaptcha(new ReCaptchaService()));
    setUpRecaptch();
  };

  const setUpRecaptch = () => {
    if (showRecaptcha) {
      if (document.querySelector(`#${recaptchaTarget} iframe`)) {
        // already rendered
        grecaptcha.reset();
      } else {
        grecaptcha.render(document.getElementById(recaptchaTarget), {
          sitekey: "6LdD7SgTAAAAALjVAm80uYL-CKmPlF1Wwt0CYjv4",
          callback: data => dispatch(defaultAction(SET_RECAPTCHA, data)),
          "expired-callback"() {
            dispatch(defaultAction(RESET_RECAPTCHA));
          }
        });
      }
      dispatch(defaultAction(DISPLAY_RECAPTCHA, true));
    }
  };

  useEffect(() => {
    if (showRecaptcha && !recaptchaCode) {
      setRecaptchaDisable(true);
    } else if (showRecaptcha && recaptchaCode) {
      setRecaptchaDisable(false);
    }
  }, [showRecaptcha, recaptchaCode]);

  const formSubmitted = async data => {
    if (disabled || recatpchaDisable) {
      return;
    }
    dispatch(defaultAction(DISPLAY_SIGNIN_PASS_ERROR, ""));
    const response = await login(
      data.email.trim(),
      data.password.trim(),
      recaptchaCode,
      submissionHistoryObject
    );
    if (response.error) {
      dispatch(defaultAction(RESET_RECAPTCHA));
      checkIfFormIsValid();
    }
  };

  const initialState = {
    email: "",
    password: ""
  };

  const {
    inputs,
    handleInputChange,
    handleSubmit,
    handleBlur,
    disabled,
    meta,
    handleFocus,
    formErrors,
    checkIfFormIsValid
  } = useFormHook(
    formSubmitted,
    initialState,
    validateSingleItem,
    "signInPopup"
  );

  const localHandleFocus = (e, field) => {
    handleFocus(e);
    setImageOpacity({
      ...imageOpacity,
      [field]: 1
    });
  };

  const localHandleBlur = (event, field) => {
    handleBlur(event);
    if (field) {
      setImageOpacity({
        ...imageOpacity,
        [field]: 0.3
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} className={setClassName}>
      <InputSection
        className={
          invalidSignInPassword
            ? "small-margin-bottom"
            : "regular-margin-bottom"
        }
      >
        <InputDiv className="sign-in-input-div">
          <Image
            src="https://cdn.cardcash.com/website/ui/elements/envelope.svg"
            imageOpacity={imageOpacity.email}
            alt="icon"
          />
          <InputContainer>
            <InputField
              autoCapitalize="off"
              error={
                ((meta.email.touched && meta.email.blurred) ||
                  meta.submitAttempted) &&
                formErrors &&
                formErrors.email
              }
              className="orange-caret"
              //type has to be text for customer service sign-ins
              type="text"
              name="email"
              placeholder="Email"
              value={inputs.email}
              onChange={handleInputChange}
              onBlur={e => localHandleBlur(e, "email")}
              onFocus={e => localHandleFocus(e, "email")}
            />
          </InputContainer>
          {formErrors && formErrors.email && (
            <ErrorMessageInner className={"animated fadeIn"}>
              {formErrors.email.errorMessage}
            </ErrorMessageInner>
          )}
        </InputDiv>
        <InputDiv>
          <Image
            src="https://cdn.cardcash.com/website/partners/starbucks/locked@svg.svg"
            alt="icon"
          />
          <InputContainer error={formErrors && formErrors.password}>
            <InputField
              error={
                ((meta.password.touched && meta.password.blurred) ||
                  meta.submitAttempted) &&
                formErrors &&
                formErrors.password
              }
              className="orange-caret"
              type="Password"
              name="password"
              placeholder="Password"
              value={inputs.password}
              onChange={handleInputChange}
              onBlur={e => localHandleBlur(e, "Password")}
              onFocus={e => localHandleFocus(e, "Password")}
              autoComplete="off"
            />
          </InputContainer>
          {formErrors && formErrors.password && (
            <ErrorMessageInner className={"animated fadeIn"}>
              {formErrors.password.errorMessage}
            </ErrorMessageInner>
          )}
          <ForgotPassword>
            <PasswordLink
              to="/pass-assist/"
              onClick={() => {
                if (showSignInPopUpModal) {
                  dispatch(defaultAction(HIDE_SIGN_IN_POPUP_MODAL));
                }
              }}
            >
              Forgot?
            </PasswordLink>
          </ForgotPassword>
        </InputDiv>
        {passErrorMsg && (
          <div className="animated fadeIn">
            <MessageContainer
              message={passErrorMsg}
              classes={"popup-cart-error-message"}
            />
          </div>
        )}
      </InputSection>
      <RecaptchaDiv
        id={recaptchaTarget}
        className={showRecaptcha ? "mar-b-10 animated fadeIn" : "hide"}
      />
      <SubmitButton
        appearDisabled={disabled || recatpchaDisable}
        styles={{ height: "64px", borderRadius: "36px" }}
        disabledBackGroundColor={"rgba(119, 121, 132, 0.48)"}
      >
        Login
      </SubmitButton>
    </form>
  );
};

export default SignInForm;
